/* @font-face {
  font-family: 'System';
  font-style: normal;
  font-weight: normal;
  src: local('IBM VGA 8x16'), url('./assets/font/WebPlus_IBM_VGA_8x16.woff') format('woff');
} */

@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.12em;
  color: white;
  background-color: transparent;
}

* {
  box-sizing: border-box;
  color: #01ff01;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.12em;
  font-weight: 100;
}

html {
  scroll-behavior: smooth;
}

body {
  /* overflow: hidden; */
  background: rgb(14, 14, 14);
  color: #01ff01;
}

.behind {
  position: absolute;
  top:50%;left:50%;
  transform: translate(-50%,-50%);
  z-index: -1;
}

img {
  filter: drop-shadow(3px 3px 3px rgba(207, 207, 207, 0.4));
}


